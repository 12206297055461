<!-- eslint-disable -->
<template>
  <DocPage :titles="titles" title="GENERAL TERMS AND CONDITIONS">
    <div>
      <h2 id="1. General Provisions">1. General Provisions</h2>
      <br /><br />
      1.1. These General Terms and Conditions establishes relationship between
      Inst and the Client and set out rights, obligations and other terms when
      the Client opens, uses and closes the Payment Account and uses other
      services provided by Inst.<br /><br />
      1.2. Before using the Inst Services, the Client must read these General
      Terms and Conditions.<br /><br />
      1.3. The General Terms and Conditions enters into force and is valid
      indefinitely when the Client registers in the System, has read the General
      Terms and Condition and expresses consent to comply with them.<br /><br />
      1.4.Inst, in connection with or through our cooperation partners’ services
      (the “Third Party”), provides the following services in accordance with
      these General Terms and Conditions, unless we and you agree otherwise:<br />
      1.4.1.Issuing and redemption of electronic money;<br />
      1.4.2.Services enabling cash to be placed on an Account as well as all the
      transactions required for operating an Account;<br />
      1.4.3.The transactions required for operating a payment account;<br />
      1.4.4.Execution of the following types of Payment transactions:<br />
      1.4.4.1.Direct debit, including one-time direct debit;<br />
      1.4.4.2.Payment transactions using a payment card or similar device;<br />
      1.4.5.Issuing payment instruments or accepting payment transactions;<br />
      1.4.6.Money remittance.<br /><br />
      1.5.The terms and conditions of other services provided by us or/and the
      Third Party are set out in the appendices to these General Terms and
      Conditions and in the separate agreements that are an integral part of the
      General Terms and Conditions. The terms and conditions set out in the
      Annexes are special provisions that go beyond the other provisions of the
      General Terms and Conditions. <br /><br />
      <h2 id="2. Definitions">2. Definitions</h2>
      <br /><br />
      2.1. <strong>Account</strong> - the account opened in our System for your
      use;<br /><br />
      2.2. <strong>Business Day</strong> - 10;00-18;00(UTC/GMT+08:00)，any day
      other than a Saturday or a Sunday or a public or bank holiday<br /><br />
      2.3. <strong>Cardholder</strong> - an individual to whom the card is
      issued to and who is authorized to use the card;<br /><br />
      2.4. <strong>Commission fee</strong> means the fee charged by us for a
      payment operation and/ or related services.<br /><br />
      2.5. <strong>Consumer</strong> means the natural person who operates under
      these General Terms and Conditions and its annexes and does not pursue
      aims which are not consistent with business, commercial or professional
      activity of this person;<br /><br />
      2.6. <strong>Confidential Information</strong> means any information which
      is marked as “Confidential” or “Proprietary” or should be reasonably
      expected to be confidential having regard to the context of disclosure or
      the nature of the information; including, without prejudice to the
      generality of the foregoing, the terms of these General Terms and
      Conditions as well as business plans, data, strategies, methods, client
      and client lists, technical specifications, transaction data and customer
      data shall be deemed confidential; <br /><br />
      2.7. <strong>Client</strong>, you or your means the natural person or
      legal entity accepting these General Terms and Conditions;<br /><br />
      2.8. <strong>Electronic money</strong> means electronically, including
      magnetically, stored monetary value as represented by a claim on the
      issuer (us or the Third Party) which is issued on receipt of funds for the
      purpose of making payment transactions, and which is accepted by a natural
      or legal person other than the electronic money issuer. For the ease of
      reference in these General Terms and Conditions when referring to
      electronic money stored on your account we will use the term ‘Funds’;<br /><br />
      2.9. <strong>General Terms and Conditions</strong> - present General Terms
      and Conditions;<br /><br />
      2.10. <strong>Payer</strong> means a natural or legal person submitting a
      payment order;<br /><br />
      2.11. <strong>Security Credentials</strong> means any personalized
      features that you create or receive and use for access to the Account or
      initiation and management of separate Services provided by us and/or
      initiation, authorization, implementation, confirmation and reception of
      payment operations;<br /><br />
      2.12.<strong>Payment Instrument</strong> means a personalized tool and /
      or certain procedures agreed between the Third Party or us and you which
      are used by you for the initiation of the Payment order.<br /><br />
      2.13. <strong>Payment transfer</strong> means a payment service when money
      is transferred to your Account under the initiative of the Payer;<br /><br />
      2.14. <strong>Payment order</strong> means an order from the Payer or the
      Recipient (payment transfer) for the provider of payment services to
      execute a payment operation;<br /><br />
      2.15. <strong>Payment operation</strong> means a money deposit, transfer
      or withdrawal initiated by the Payer or the Recipient;<br /><br />
      2.16. <strong>Payment transaction</strong> means an act, initiated by the
      payer or on his behalf or by the payee, of placing, transferring,
      irrespective of any underlying obligations between the payer and the
      payee;<br /><br />
      2.17. <strong>Prices</strong> means the prices for our services and
      operations confirmed in accordance with the procedure established by
      us;<br /><br />
      2.18. <strong>Recipient</strong> means a natural (private) or legal person
      indicated in the Payment order as a Recipient of the payment;<br /><br />
      2.19. <strong>Services</strong> means payment and e-money services
      identified in the clause 1.4 of these General Terms and Conditions and
      provided by us; <br /><br />
      2.20. <strong>System, we, our, or us</strong> means Inst and its
      cooperation partners or the Third Party; <br /><br />
      2.21. <strong>A unique identifier</strong> is a combination of letters,
      numbers and symbols that we provide to you and is used to identify the
      payment services involved in the Payment transaction and / or your Account
      used in the Payment transaction.<br /><br />
      2.22. Third Party is Unioncash EU UAB ,company code 306049758,
      registeredoffice:Vilnius, J. Basanaviciausg.26<br /><br />
      <h2 id="3. Registration in the System">3. Registration in the System</h2>
      <br /><br />
      3.1. To start using our services, you must first submit an application to
      open an account and provide the information and documents we request for
      the Account to legal persons and natural persons.<br /><br />
      3.2. The Account of a natural person cannot be used for income received
      from a business relationship unless there are appropriate supporting
      documents (business certificate, certificate of individual activity,
      etc.). If the Client violates this clause, we have the right to close the
      Account.<br /><br />
      3.3. In the event of a higher risk, the client must provide additional
      documentation to meet the requirements for enhanced customer
      identification. During this waiting period, we have the right to suspend
      the use of the Account and if the client does not respond within 45 (forty
      five) days of such request, we have the right to terminate the business
      relationship.<br /><br />
      3.4. We reserve the right to refuse to register you as a new client
      without giving any reason, but we guarantee that refusal to register will
      always be based on important reasons that we do not have to or have no
      right to disclose.<br /><br />
      3.5. You are solely responsible for providing the information and
      documents we request. You are responsible for ensuring that all
      information provided during or at any time after the registration process
      is accurate and correct.<br /><br />
      3.6. We reserve the right to refuse your right to use the Services if you
      do not provide the information and documents requested or provide
      inappropriate documents. We are not obligated to provide the Services to
      the requesting client and may, in our sole discretion, reject an
      application for the Services.<br /><br />
      3.7. After we have verified the documents and information provided by you,
      you have the right to start using Account and our Service.<br /><br />
      3.8. You have the right to open one account unless we expressly approve
      the opening of any additional accounts.<br /><br />
      3.9. If your account has not been used for more than 12 months and there
      are no funds in it, inform you about it and without receiving your answer,
      we reserve the right to close it automatically.<br /><br />
      3.10. If you have not used your Account for more than 12 months (there are
      no transactions on it) but it has funds in it and after our report, you do
      not withdraw the money, we have the right to charge an inactive account
      administration fee. This fee is used to cover the administration costs of
      an unused Account until the Account balance becomes empty and is
      automatically closed. You will be informed in advance to your email
      address.<br /><br />
      <br />
      <h2 id="4. Issuance and redemption of Electronic money">
        4. Issuance and redemption of Electronic money
      </h2>
      <br /><br />
      4.1. Your Account allows you to deposit, transfer, hold funds in the
      Account for transfers, execution of local and international money
      transfers, as well as to receive money in the Account, pay for goods and
      services and perform other operations directly related to money transfers.
      You may use all of our services only if you have completed identification
      procedures in accordance with the rules set forth in our System.<br /><br />
      4.2. Funds in your Account are considered electronic money that we issue
      after you transfer or deposit money to your Account. When you deposit or
      transfer money to your Account and the Third Party receive money, we
      credit it to your Account, at the same time issuing Electronic Money at
      face value. Electronic money is credited and stored in your Account.<br /><br />
      4.3. You choose the specific method of depositing or transferring funds to
      your Account by selecting a certain method/function in the Account, which
      provides instructions on how to deposit money for each payment
      instrument.<br /><br />
      4.4. The nominal value of electronic money corresponds to the value of the
      money deposited or transferred to your Account (after deducting the
      standard commission fee applicable to a specific payment instrument).
      <br /><br />
      4.5. The electronic money held in your Account is not a deposit and under
      no circumstances do we pay any interest on the electronic money held in
      your Account and do not provide any other benefits related to the
      Electronic money storage period.<br /><br />
      <br />
      <h2 id="5. Authorisation of Payment transactions">
        5. Authorisation of Payment transactions
      </h2>
      <br /><br />
      5.1.A payment transaction shall be considered to be authorised only when
      the Payer has given consent to execute the Payment transaction. In the
      absence of consent, the Payment transaction shall be considered to be
      unauthorised.<br /><br />
      5.2. The consent may be provided to us in the form and manner agreed
      between us and you. In case if the consent is provided in written, it
      shall be signed properly. The consent may be confirmed by using the
      security code given to you by us and login credentials during the time of
      the creation of the Account or by other identity verification means. The
      consent may be expressed by other form and manner needed for the concrete
      Services and / or indicated in the additional agreement between us and
      you.<br /><br />
      5.3. You are obligated to check the information about the executed Payment
      transactions at least 1 (one) time per month.<br /><br />
      5.4. You are obligated to inform us in writing about the unauthorised or
      improperly executed Payment operations, including the noticed mistakes,
      inaccuracies in the statement without undue delay from the acknowledging
      of such circumstances and in any case not later than within 6 (sixth)
      months after the debit date. The other terms of informing us about the
      circumstances described above may be used in cases set forth by the
      additional agreements signed between the Parties.<br /><br />
      5.5. In case you do not notify us about the circumstances described in the
      clause 5.4 of these General Terms and Conditions within the terms
      indicated in these General Terms and Conditions and the additional
      agreements between the Parties, Inst has no obligation to return the
      amount of the unauthorised Payment transaction.<br /><br />
      5.6. If the Client denies the authorisation of the executed Payment
      transaction or the payment transaction were executed improperly, we are
      obligated to prove that the Payment transaction was authenticated,
      accurately recorded, entered in the accounts and not affected by a
      technical breakdown or some other deficiency in the Services provided by
      us.<br /><br />
      5.7. The use of the means of identification and access to the Account
      credentials is the sufficient proof, that the Client authorised the
      Payment transaction or acted fraudulently or failed with intent or gross
      negligence to fulfill one or more of his obligations set forth in the
      clauses 13.1 – 13.6 of these General Terms and Conditions.<br /><br />
      5.8. In accordance to the terms indicated in the clause 5.5 of these
      General Terms and Conditions or having determined that the Payment
      operation was not authorised by the Client, we without undue delay, return
      the amount of the unauthorised Payment transaction to the Client and,
      where applicable, - restore the balance of the Account from which this
      amount was written down and which would have existed if the unauthorised
      Payment transaction had not been executed, unless we have reasonable
      grounds to suspect fraud and inform the supervisory authority in writing
      of those reasons.<br /><br />
      5.9. The Client may incur all the losses resulting from unauthorised
      payment transactions if these loses have been incurred due to:<br />
      5.9.1. Use of a lost or stolen payment instrument, if the authorisation
      happened before client has notified Inst of the loss or theft of payment
      instrument;<br />
      5.9.2. Illegal acquisition of a payment instrument if the Client had not
      protected personalized security features (including identity verification
      instruments).<br /><br />
      5.10. The Client shall be liable for any losses caused by unauthorised
      Payment transaction, if the Client incurred them by acting fraudulently or
      by failing to fulfil, with gross negligence or intent, one or more of his
      obligations set forth in the clauses 13.1-13.6 of these General Terms and
      Conditions and additional agreements signed by the Parties.<br /><br />
      5.11. Other terms of the liability of the Parties for the unauthorized
      Payment operations may be indicated in the additional agreements between
      the Parties.<br /><br />
      <h2 id="6. Execution and cancellation of Payment orders">
        6. Execution and cancellation of Payment orders
      </h2>
      <br /><br />
      6.1. Generally, the payment order shall be considered received by us (the
      execution period of such Payment order shall start to run) on the day of
      its receipt or, if the Payment order is not received on our business day,
      the Payment order shall be considered received on the next business day.
      If for the execution of the Payment transaction we require you to provide
      supporting documents, the Payment order shall be considered to be received
      by us on the day when you submit the requested documents.<br /><br />
      6.2. Generally, the payment order we received on a Business day, but not
      during our business hours, is considered to be received on the next
      Business day.<br /><br />
      6.3. Payment orders in our System are executed immediately (up to a few
      minutes, unless the Payment transaction is suspended due to the cases
      provided for in the legislation and these General Terms and Conditions),
      regardless of our business hours.<br /><br />
      6.4. We execute Payment transactions according to the Unique identifier
      provided in the Payment Order - IBAN Account number.<br /><br />
      6.5. We are not liable if the Payment order does not contain a unique
      identifier and / or is incorrect and / or the Beneficiary's payment
      service provider has set another unique identifier for the proper
      execution of such Payment transaction (including funds in the
      Beneficiary's payment account).<br /><br />
      6.6. We have the right to request additional and / or other mandatory
      information (such as amount and currency, Beneficiary's name, surname /
      legal entity name / payment code) that must be provided to us in the
      Payment order for the purpose of proper execution of the Payment
      transaction.<br /><br />
      6.7. Prior to the execution of a Payment order, we undertake to provide
      you with information on the possible maximum deadlines for the execution
      of a particular Payment order, the Commissions payable and how they are
      distributed. You can find this information on our website and in your
      personal Account.<br /><br />
      6.8. The procedure of cancellation of the Payment order:<br />
      6.8.1. The Payment order cannot be canceled after we receive it, except
      for cases provided in these General Terms and Conditions;<br />
      6.8.2. If the Payment operation had been initiated by the Recipient, the
      Payer cannot cancel the Payment order after the Payment order has been
      sent or the Payer has given the consent to the Recipient to perform the
      Payment operation;<br />
      6.8.3. The Payment order may be canceled only if the Client (Payer) and we
      agree on it, but the consent of the Beneficiary is required.<br /><br />
      6.9. We have the right to record and store any Payment orders submitted in
      a manner agreed with us, as well as to record and store information about
      all Payment transactions performed by you or in accordance with your
      Payment orders. We may provide the above-mentioned records to you and / or
      third parties who, on the grounds established by law, have the right to
      receive such data as proof confirming the submission of Payment orders and
      / or performed Payment transactions.<br /><br />
      6.10. We have the right to refuse to execute a Payment order if there are
      reasonable doubts that the Payment order was submitted by you or your
      authorized representative, the Payment order or the submitted documents
      are legal. In such cases, we have the right to require you to additionally
      confirm the submitted Payment order and / or submitdocuments confirming
      the right of persons to manage the funds in the Account or other documents
      specified by us in a manner acceptable to us by e-mail at your costs. We
      will not be liable for any losses that may result from the refusal to
      execute the submitted Payment order due to your refusal to provide
      additional information or documents.<br /><br />
      6.11. You are responsible for ensuring that there is sufficient money in
      your Account in the relevant currency to execute the Payment order.<br /><br />
      6.12. We have the right to use third parties to partially or fully execute
      your Payment order if your interests and / or the essence of the Payment
      order so require. In the event that the essence of your Payment order
      requires that the Payment transaction be further sent and executed by
      another financial institution, but this institution suspends the Payment
      order, we are not responsible for such actions of that financial
      institution, but we try to find out the reasons for the Payment order
      suspension.<br /><br />
      6.13. We have the right to suspend and / or terminate the execution of
      your Payment Order if required by law or for other reasons beyond our
      control.<br /><br />
      6.14. If we refuse to execute your Payment order, we will promptly notify
      you or provide you with the necessary conditions to access such notice,
      unless such notice is technically impossible or prohibited by law.<br /><br />
      6.15. We do not accept or execute your Payment orders in order to carry
      out transactions on your Account if the funds in the Account are seized,
      your right to manage the funds is otherwise restricted by law or the
      transactions are suspended in accordance with applicable law.<br /><br />
      6.16. If the money transferred by the Payment order is returned due to
      reasons beyond our control (inaccurate data of the Payment order, the
      Beneficiary's account is closed, etc.), the refunded amount will be
      credited to your Account. The commission fees paid by the Payer for the
      execution of the Payment order shall not be refunded, and other fees
      related to the refund and applicable to us may be deducted from your
      Account.<br /><br />
      6.17. The terms and conditions for the execution of Payment transactions
      and the duration of the execution of other Services are set out in these
      General Terms and Conditions, the Price List and other additional
      agreements between us and you.<br /><br />
      6.18. When you are a Payer and the Payment transaction is performed in
      euros in the Republic of Lithuania and other Member States, we ensure that
      the amount of the Payment transaction is credited to the Beneficiary's
      account on the day of the Payment transaction, if the execution date of
      the Payment order is not our Business day, it is the next Business day.<br /><br />
      6.19.We may provide you with an Account statement for your Payment
      transactions, which could be can be submitted through your Account,
      including the following information:<br />
      6.19.1.Amount of the Payment transaction in the currency specified in the
      Payment order;<br />
      6.19.2.Commissions paid for Payment transactions and how they are
      distributed;<br />
      6.19.3.The valid exchange rate and the amount of the Payment transaction
      after the exchange rate, if the currency was exchanged during the Payment
      transactions;<br />
      6.19.4.Date of debiting from the Account;<br />
      6.19.5.Date of income to the Account;<br />
      6.19.6.Other information that will be provided to you in accordance with
      the applicable legal acts of the Republic of Lithuania.<br /><br />
      <br />
      <h2 id="7. Account blocking">7. Account blocking</h2>
      <br /><br />
      7.1. The Account may be blocked by the Client’s initiative and / or the
      Account (including the payment instrument if such is given to the Client)
      may be blocked if the Client submits a respective request to us. We have
      the right to demand that the request submitted by the Client's oral
      request to block the Account (including the payment instrument if such is
      given to the Client) be subsequently approved in writing or in another
      manner acceptable to us.<br /><br />
      7.2. If we have the reasonable doubts that the request indicated in the
      clause 7.1 of these General terms and Conditions is not submitted by the
      Client, we have the right to refuse to block the Account (including the
      payment instrument if such is given to the Client). In such cases, we
      shall not be liable for any losses that may result from the failure to
      comply with the said request.<br /><br />
      7.3. We have the right, in the following situation, to block the Account
      (suspend the execution of Payment transactions in whole or in part, and
      limit the crediting of funds to the account) and / or the Payment
      instrument, if such instrument has been provided to you:
      <br />
      7.3.1.If there are objectively justified reasons related to the security
      of the funds and / or Payment instrument in the Account, the alleged
      illegal or unfair use of the funds and / or payment instrument in the
      Account;<br />
      7.3.2.In the event that you do not comply with the terms of these General
      Terms and Conditions;<br />
      7.3.3.In the event that we have reasonable suspicions that the funds in
      the Account may be used for illegal activities, including, but not limited
      to, the performance of criminal activities ;<br />
      7.3.4.On other grounds established by the legal acts of the Republic of
      Lithuania and / or in the cases specified in the additional agreements
      signed by the Parties.<br /><br />
      7.4. We undertake to inform you about your suspected or actual fraud or
      threat to the security of the Services in your personal Account by
      telephone or e-mail, by post or any other means which is secure and
      appropriate at the time.
      <br /><br />
      <br />
      <h2 id="8. Prices for Services (Commission fee and currency exchange)">
        8. Prices for Services (Commission fee and currency exchange)
      </h2>
      <br /><br />
      8.1. The prices of our Services are listed on our website at
      [https://inst.money/] (the “Final exchange rate”) or in the business
      agreement that we signed with you additionally (the “Business Agreement”).
      When any conflicts exist between the Price List and the Business
      Agreement, we shall have the sole discretion on which one should
      prevail.<br /><br />
      8.2. Our standard Services are charged in accordance with these General
      Terms and Conditions and the Price List. Services that are not defined
      here and / or in the Price list will be charged at individual prices that
      you will be notified of before using such services.<br /><br />
      8.3. You pay us a commission for the payment services and / or related
      services we provide. The commission fee is specified in the Price List and
      / or in the Business Agreement signed with you.<br /><br />
      8.4. All Prices and Commissions payable by you will be deducted from your
      Account balance. If your Account balance is insufficient or your Account
      balance becomes negative, we reserve the right to bill you for any
      deficiencies/shortfall.<br /><br />
      8.5. If we do not have the possibility to deduct the Prices and / or
      Commission fee payable for the Services provided from the balance of your
      Account, we will issue a separate invoice for the amount of the debt.
      Invoices shall be paid within 10 (ten) days from the date of issue of the
      invoice. In the event of late payment, we reserve the right to charge
      interest and / or terminate our Services immediately upon written notice
      to you.<br /><br />
      8.6. In the event that there are insufficient funds in your Account to
      execute the Payment transaction and pay the Commission Fee, we have the
      right to refuse to execute the Payment transaction.<br /><br />
      8.7. Exchange rates will be provided to you prior to the Payment order. We
      may apply the changed base exchange rate immediately without notice.<br /><br />
      8.8. In the event that the currency in which the Payment transaction is
      made is different from the currency in which the Account is debited, the
      conversion of such currencies shall be carried out in accordance with
      European Central Bank or other standard rate effective at the time of
      transaction.<br />
      <br /><br />
      <h2 id="9. Communication">9. Communication</h2>
      <br /><br />
      9.1. These General Terms and Conditions, all communications, information
      about any changes to the Services and the Price Information are in
      English. You acknowledge that all communication between us and you will be
      in English.<br /><br />
      9.2. Information is provided to you in the following ways:<br />
      9.2.1.the information may be provided in person, through Your Account,
      sent by mail, e-mail, telephone and other means of telecommunications,
      including electronic means;<br />
      9.2.2.the information may be published on our website, and we may also
      provide the information in the press or other media. It is considered that
      the information provided to the public has been duly communicated to you,
      except in cases of mandatory requirements of the laws and other legal acts
      of the Republic of Lithuania and / or cases where we are obliged to inform
      you personally.<br /><br />
      9.3. You acknowledge that any communication between us and you will
      primarily take place through the Account and email. Disclosure of any
      information in our account and email means that the information provided
      is relevant and effective.<br /><br />
      9.4. You acknowledge that communication through the Account will only take
      place if you enter in your personal account the login details or other
      requested personal security credentials we have provided to you for the
      purpose of authenticating you as a Client.<br /><br />
      9.5. Possible communication by e-mail is performed between our e-mail
      specified on the website and your e-mail that you provided during
      registration. E-mail message is considered to be duly delivered on the
      following Business day.<br /><br />
      9.6. If you communicate by phone, you will be verified based on your data.
      Telephone communication between us and you is possible during our working
      hours. The message is considered to have been properly transmitted by
      telephone at the time of the conversation with you.<br /><br />
      9.7. When communicating by mail, letters are delivered to the other
      party's address. The letter will be considered to have been duly served on
      the third day after sending out, even if notification that the letter
      cannot be served to the other party or that the other party has rejected
      the letter or has not withdrawn it within the prescribed time limit, even
      if the addressee was unaware of the letter.<br /><br />
      9.8. Information published on our website, in your Account, as well as
      publicly disclosed information is deemed to have been properly served on
      the date of publication / publication of such information.<br /><br />
      9.9. You agree that we may record, even without notice, any ongoing
      communication between us and you, using any technical means available, and
      archive all records, as well as copies of any information and documents we
      receive from you and third parties. You agree that we may use this
      information at any time for the purposes set out in these General Terms
      and Conditions or to ensure that these General Terms and Conditions are
      complied with.<br /><br />
      <h2 id="10. Prohibited activities">10. Prohibited activities</h2>
      <br /><br />
      10.1.When using our Services, you are prohibited from receiving or making
      payments in connection with the following activities:<br />
      10.1.1.Illegal gambling services (including, but not limited to, illegal
      online casinos, sports betting, betting, reverse auctions and
      lotteries);<br />
      10.1.2.Quick enrichment schemes, Ponzi schemes, snowball schemes,
      investment clubs or similar activities;<br />
      10.1.3.Adult or sexual content, escort services or modeling agencies;<br />
      10.1.4.Mass email services, SMS services or customer marketing lists;<br />
      10.1.5.Prescription drugs, prohibited substances or components thereof;
      drug paraphernalia;<br />
      10.1.6.Counterfeit or forged goods, novelty ID;<br />
      10.1.7.Dangerous or restricted goods (including but not limited to
      explosives, radioactive materials, toxic substances, batteries,
      fireworks);<br />
      10.1.8.Weapons, knives and ammunition;<br />
      10.1.9.Protected works of art, history and culture;<br />
      10.1.10.Restricted electronics (eg, cable television decoders, radars, and
      surveillance equipment);<br />
      10.1.11.any other goods or services the sale, supply, delivery, offer or
      marketing of which is prohibited or restricted in the jurisdiction of the
      Seller or in any jurisdiction in which any of its customers is located. We
      may change or extend the list of prohibited goods or services at any time
      in our sole discretion. If you supply goods or services that are subject
      to a subsequent extension of the list, you shall immediately cease to make
      payments for such goods or services. In the event that you fail to comply
      with such termination, we reserve the right to terminate the business
      relationship and the validity of these General Terms and Conditions. If
      you are in doubt as to whether your goods or services fall into any of the
      categories listed, you should first consult us before offering such goods
      or services. In case that your prohibited activities cause any damages to
      Inst, you shall make full compensation to Inst.<br /><br />
      10.2. We may change or extend the list of prohibited goods or services
      referred to in clause 10.1 at any time in our sole discretion. If you
      supply goods or services that are subject to a subsequent extension of the
      list referred to in clause 10.1, you shall immediately cease to make
      payments for such goods or services. In the event that you fail to comply
      with such termination, we reserve the right to terminate the business
      relationship and the validity of these General Terms and Conditions. If
      you are in doubt as to whether your goods or services fall into any of the
      categories listed, you should first consult us before offering such goods
      or services.<br />
      <br /><br />
      <h2 id="11. Changes to these General Terms and Conditions and Prices">
        11. Changes to these General Terms and Conditions and Prices
      </h2>
      <br /><br />
      11.1. These General Terms and Conditions may be amended from time to time.
      <br /><br />
      11.2. We reserve the right to unilaterally change these General Terms and
      Conditions, applicable Prices and Commissions and / or Terms of Service.
      <br /><br />
      11.3. .If you do not agree to the proposed changes, you have the right to
      terminate these Terms and Conditions immediately and free of charge before
      they take effect.<br /><br />
      11.4. We have the right to change these General Terms and Conditions, the
      applicable Prices and Fees and / or the Terms and Conditions of the
      Service for important reasons and without notifying the terms. In this
      case, you have the right to terminate our services immediately by
      notifying us in writing or in any other manner agreed between us and you
      of the termination.<br /><br />
      11.5. Termination of the Services in accordance with clauses 11.3 or 11.4
      does not release you from obligations arising before the date of
      termination of the Services for their proper performance.<br /><br />
      11.6. If you do not use your right to terminate the Services with the
      clauses 11.3 or 11.4 of these General Terms and Conditions, you shall be
      deemed as accepted the changes to these General Terms and Conditions,
      applicable Prices and Commission fees and / or the terms of Services made.
      If you agree with the changes to these General Terms and Conditions,
      applicable Prices and Commission fees and / or the terms of Services, then
      you are not entitled subsequently to submit to us your objection and / or
      claims regarding the content of such changes.<br /><br />
      <h2 id="12. Validity and termination">12. Validity and termination</h2>
      <br /><br />
      12.1. You have the right to terminate the Services and related Agreement
      by notifying us in writing 30 (thirty) days in advance. You may also
      terminate the Services and related Agreement free of charge at any time
      before the proposed effective date of the amendments to these General
      Terms and Conditions.<br /><br />
      12.2.We reserve the right to terminate the Services from the date of
      notice to you if:<br />
      12.2.1.You file a petition for bankruptcy, become insolvent, or make any
      arrangement or composition with or assignment for the benefit of its
      creditors, or a receiver is appointed for you or your business, or you
      into liquidation either voluntarily (otherwise than for reconstruction or
      amalgamation) or compulsorily;<br />
      12.2.2.You violate these General Terms and Conditions or act in violation
      of these General Terms and Conditions and do not provide remedies within
      the time period specified by us;<br />
      12.2.3.We have reasonable grounds to suspect that you or a person
      authorized to act on your behalf are acting in a manner inconsistent with
      generally binding legal requirements, good morals, fair dealing,
      anti-money laundering conditions, or that your circumstances have changed
      materially to ensure compliance with these General Terms and Conditions;
      <br />
      12.2.4.You have outstanding obligations to us;<br />
      12.2.5.Competent authorities (the police, Bank of Lithuania or others)
      instructs us to terminate a business relationship with you;<br />
      12.2.6.The information provided by you and used in these General Terms and
      Conditions appears to be false, incomplete, inaccurate and
      incomprehensible;<br />
      12.2.7.It has been proven that your fraudulent activities were related to
      the use of your Account or that you and / or your employees have been
      prosecuted for fraudulent activities;<br />
      12.2.8.If we continue to provide our services to you, we will violate the
      rules or recommendations of our bank or other partners.<br /><br />
      12.3.For other reasons, we have the right to terminate these General Terms
      and Conditions and its supplements by giving you 60 days notice of
      termination.<br /><br />
      12.4.Termination of these General Terms and Conditions shall not relieve
      the Parties of any obligations to each other arising prior to the date of
      termination of these General Terms and Conditions for the proper
      performance thereof.<br /><br />
      <h2 id="13. Security requirements for the Account use">
        13. Security requirements for the Account use
      </h2>
      <br /><br />
      13.1. You are responsible for the safety of devices used to log in to the
      Account, shall not leave them unattended, in public places or otherwise
      easily accessible to third persons.<br /><br />
      13.2. It is prohibited to connect to your account using the services of a
      layered router (TOR network), constantly connecting from an Internet
      Protocol (IP) address in different countries.13.3. It is recommended to
      update software, applications, anti-virus programs, browsers and other
      programs in time.<br /><br />
      13.3. It is recommended to protect devices with passwords, PIN codes or
      other safety instruments.<br /><br />
      13.4. The Client undertakes to carefully evaluate incoming e-mails, even
      if Inst is listed as the sender. Inst never asks customers to download
      attachments or install software. Fraud e-mail attachments may contain
      viruses that could harm your device or compromise your Account.<br /><br />
      13.5. It is recommended not to click on unknown links, open unknown
      documents, install software or application from unknown, unreliable
      sources or visit unsafe websites.<br /><br />
      13.6. If you notice any suspicious activity on his account and think that
      third persons may have logged in to system for the using of the Services,
      you shall:<br />
      13.6.1. Immediately inform us thereof and request to block your
      Account;<br />
      13.6.2. In order to continue to use the Account, you shall change the
      password, use other additional account confirmation instruments or use
      safer instruments and delete unsafe additional login confirmation
      instruments.<br /><br />
      <h2 id="14. Liability of the Parties">14. Liability of the Parties</h2>
      <br /><br />
      14.1. Each Party is liable for all fines, forfeits, and other losses which
      incurs due to violation of the Agreement by the it (the “Guilty Party”).
      The guilty Party undertakes to reimburse to the other Party direct damage
      incurred due to such liability to the affected Party.<br /><br />
      14.2. If the Payment transaction is executed incorrectly, we will only be
      liable through our own fault. We are not responsible for the mistakes of
      the client or any third parties.<br /><br />
      14.3. If you initiate a Payment order, the Payment order shall be executed
      by identifying the unique identifier, such Payment order shall be deemed
      duly executed if it has been executed in accordance with the specified
      unique identifier. We have the right, but not the obligation, to verify
      that the unique identifier provided in the Payment order we receive
      corresponds to the name and surname of the account holder.<br /><br />
      14.4. If the unique identifier is presented to us with the Account to be
      credited or debited from the Account, the Payment order is deemed to be
      executed properly if it was executed according to the specified unique
      identifier. If we carry out the said inspection (for example, in the
      prevention of money laundering risk) and find out clear mismatch between
      the unique identifier submitted to us and the Account holder's name, we
      shall have the right not to execute such a Payment order.<br /><br />
      14.5. We are responsible for a duly initiated Payment order in accordance
      with these General Terms and Conditions and / or additional agreements
      signed by the Parties.<br /><br />
      14.6. If you (the Payer) properly initiate the Payment order and the
      Payment transaction is not executed or executed incorrectly, we will
      immediately and free of charge take steps to trace the Payment transaction
      and inform you about the search results.<br /><br />
      14.7. We are responsible for the refund of the already paid Commission fee
      in case the Payment order has not been executed or improperly executed due
      to our fault.<br /><br />
      14.8. We are not responsible for claims between the Beneficiary and the
      Payer and we do not deal with such claims.<br /><br />
      <h2 id="15. Force Majeure">15. Force Majeure</h2>
      <br /><br />
      15.1. Under no circumstances shall a Party be liable for non-compliance
      with the General Terms and Conditions if the Party proves that the
      Contract has not been performed due to force majeure circumstances, which
      shall be proved in accordance with the procedure established by law. The
      Party shall notify the other Party in writing about the circumstances of
      force majeure within 10 (ten) calendar days from the date of occurrence of
      such circumstances.<br /><br />
      15.2. Without limiting clause 15.1, we shall not be liable for any failure
      of any IT system, communication system or payment system, whether such
      failure is caused by a failure of hardware or software.<br /><br />
      <h2 id="16. Representation and Warranties">
        16. Representation and Warranties
      </h2>
      <br /><br />
      16.1. You represent and warrant that:
      <br />
      16.1.1. If you are a corporate entity, you are validly incorporated and
      lawfully exist under the laws of the jurisdiction of incorporation or any
      country or territory in which you conduct business;<br />
      16.1.2. Your execution of and performance under these General Terms and
      Conditions in no way breaches, contravenes, violates or in any manner
      conflicts with any legal obligation including, without limitation, your
      corporate charter or similar document or any agreement between you and any
      third party or any affiliated entity;<br />
      16.1.3. You have obtained and will maintain all necessary consents,
      authorisations, permissions and other facilitating acts in order to
      lawfully perform your obligations under these General Terms and
      Conditions;<br />
      16.1.4. You may lawfully conduct your business in any country or territory
      into which you sell, provide, deliver, promote or market your goods or
      services that you have obtained all necessary authorisations, clearances,
      licences or consents to do so;<br />
      16.1.5. All information provided by you to us in connection with your
      Application and these General Terms and Conditions is and remains complete
      and accurate;<br />
      16.1.6.The person entering into these General Terms and Conditions on your
      behalf is duly authorised to do so;<br />
      16.1.7. You do not offer and do not intend to offer goods or services
      prohibited under section 10; <br />
      16.1.8. You will always comply with your obligations under these General
      Terms and Conditions in accordance with applicable law.<br /><br />
      <h2 id="17. Personal Data Protection">17. Personal Data Protection</h2>
      <br /><br />
      17.1. Where one party acts as the data processor (as defined in the
      Regulation (EU) 2016/679 of the European Parliament and the Council,
      hereinafter the “Data Processor”) of personal data which is processed by
      the other party as the Data Controller, the Data Processor shall at all
      times follow the Data Controller’s reasonable instructions with regards to
      the personal data processed.<br /><br />
      17.2. The processing of personal data, data subjects and their rights,
      conditions for the storage of personal data are defined in our Privacy
      Policy.<br /><br />
      <h2 id="18. Confidentiality">18. Confidentiality</h2>
      <br /><br />
      18.1. During and after the validity of these General Terms and Conditions,
      each party will use and reproduce the confidential information of the
      other party only for the purposes of these General Terms and Conditions
      and only to the extent necessary for this purpose. Information to your
      employees, consultants or independent contractors on need to know basis
      can be provided only prior to signing non-disclosure consent.<br /><br />
      18.2. Notwithstanding the foregoing, a party shall not be deemed to have
      breached the confidentiality provisions if required to do so by law or by
      order of a competent court or governmental authority.<br /><br />
      18.3. No obligation of confidentiality shall apply to information which<br />
      (i) is in the public domain or becomes public knowledge without the action
      of the other party;<br />
      (ii) is known to the receiving Party without restriction before being
      received from another Party by its own independent sources as evidenced by
      written records of such Party and not directly or indirectly required by
      the other Party; <br />
      (iii) is obtained by a party from any third party legally entitled to
      transmit such information, without any obligation to keep such information
      confidential; or<br />
      (iv) is created independently by employees or agents of the receiving
      Party, provided that such Party can demonstrate that their employees or
      agents did not have access to the confidential information.<br /><br />
      <h2 id="19. Applicable law and dispute settlement">
        19. Applicable law and dispute settlement
      </h2>
      <br /><br />
      19.1. These General Terms and Conditions are drawn up and interpreted in
      accordance with Lithuanian law. Lithuanian law applies to the conditions
      not covered by the General Terms and Conditions.<br /><br />
      19.2. The disputes between you and us shall be solved through
      negotiations.<br /><br />
      19.3. In the event that the dispute cannot be resolved through
      negotiations, you may file a complaint by mail or email, stating your
      name, contact details and relevant information showing why we have
      violated your legal rights and interests. You may add other available
      evidence to substantiate the need for such a complaint. To file a formal
      complaint, email us support@inst.money . We will provide an answer within
      15 (fifteen) working days of receiving your question, unless in
      exceptional cases where it is not possible to provide an answer within 15
      working days, we may take up to 35 (thirty five) working days to respond
      and notify you separately.<br /><br />
      19.4. If you would like to contact us for any reason related to these
      General Terms and Conditions other than those described above, you may
      contact us by email support@inst.money.<br /><br />
      19.5. In the event that the dispute cannot be resolved through
      negotiations, the disputes shall be settled in the courts of the Republic
      of Lithuania in accordance with the procedure established by the laws of
      the Republic of Lithuania.<br /><br />
      <h2 id="20. Final provisions">20. Final provisions</h2>
      <br /><br />
      20.1. You may not assign any of your rights under these Terms to a third
      party without our prior written consent.<br /><br />
      20.2. You and we are independent contractors under these General Terms and
      Conditions and nothing here shall be construed as a partnership, joint
      venture or agency relationship between us.<br /><br />
      20.3. If any court of competent jurisdiction finds any provision of these
      General Terms and Conditions to be invalid, illegal or unenforceable, that
      provision will be severed from the remainder of these Terms and
      Conditions, which will continue in full force and effect to the extent
      permitted by law.<br /><br />
      20.4. The Parties shall immediately inform each other of all circumstances
      relevant to the proper implementation of these General Terms and
      Conditions. At our request, you must indicate the following circumstances
      (for example, in the event of a change in the signature of a legal
      representative, the opening and filing of bankruptcy proceedings,
      reorganization, reorganization, etc.), regardless of whether this
      information has been provided to public registers.<br /><br />
      20.5. To protect your funds from possible illegal actions by third
      parties, you must immediately notify us in writing of the theft or other
      loss of your identity document.<br /><br />
      20.6. The Parties shall promptly notify each other of any changes in their
      contact details. At our request, you must provide the relevant documents
      proving that the contact information has changed. Failure to comply with
      these obligations shall mean that the notice sent on the basis of the most
      recent contact information provided to the other Party has been duly
      served and that any obligation fulfilled on the basis of such contact
      information has been duly fulfilled. You acknowledge that we have the
      right to notify you of a change in our contact information by posting it
      publicly.
    </div>
  </DocPage>
</template>

<script>
import DocPage from '@/views/DocPage.vue';

/**
 * @author yuanzeyu
 * @date 2020-02-11
 * @desc
 */
export default {
  name: 'Terms',
  components: { DocPage },
  data() {
    return {
      titles: [
        '1. General Provisions',
        '2. Definitions',
        '3. Registration in the System',
        '4. Issuance and redemption of Electronic money',
        '5. Authorisation of Payment transactions',
        '6. Execution and cancellation of Payment orders',
        '7. Account blocking',
        '8. Prices for Services (Commission fee and currency exchange)',
        '9. Communication',
        '10. Prohibited activities',
        '11. Changes to these General Terms and Conditions and Prices',
        '12. Validity and termination',
        '13. Security requirements for the Account use',
        '14. Liability of the Parties',
        '15. Force Majeure',
        '16. Representation and Warranties',
        '17. Personal Data Protection',
        '18. Confidentiality',
        '19. Applicable law and dispute settlement',
        '20. Final provisions',
      ],
    };
  },
};
</script>
